import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { AppDispatch, RootState } from './store';

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export type ThunkAppDispatch = ThunkDispatch<RootState, void, Action>;
export const useAppThunkDispatch = () => useDispatch<ThunkAppDispatch>();

export interface ThunkApiDefaultConfig {
  dispatch: AppDispatch;
  state: RootState;
  rejectValue: string;
}

export const useRedux = () => {
  const dispatch = useAppDispatch();
  const thunkDispatch = useAppThunkDispatch();
  const storeState = useAppSelector(state => state);
  return {
    dispatch,
    thunkDispatch,
    storeState,
  };
};

export default useRedux;
